/* body scrollbar style */

body{
    /* background-color: #F7F8FB;  */
    background-color: #F7F8FB;
    margin: 0;
    overflow: hidden !important;
    height: 100vh;
}

body::-webkit-scrollbar{
    width: 0.25rem;
    height: 0.25rem;  
}

body::-webkit-scrollbar-track{
    border-radius: 0.625rem;
    background-color: transparent;
    border: 0.0625rem solid transparent;
}

body::-webkit-scrollbar-thumb{
    border-radius: 0.625rem;
    background-color: #ACB5BD;
    border: 0.0625rem solid #ACB5BD;
}

body::-webkit-scrollbar-thumb:hover{
    background-color: #495057;
    border: 0.0625rem solid #495057;
}  